<template>
  <div>
    <v-row class="d-flex justify-center mx-1 mt-md-4 mt-1">
      <v-col cols="12" sm="10" xl="8">
        <v-row>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="3">
            <Index :initiallyOpen="['Ejemplos']" />
          </v-col>
          <v-col cols="12" md="9" class="text-left">

            <a href="#blink" class="index" @click="$vuetify.goTo('#blink')">
              <h1 id="blink" class="pt-2">Blink</h1>
            </a>
            <p>
              Enciende y apaga un <strong>led</strong> (conectado al pin 13) cada un segundo.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>blink.pini</h3>
                <p>
                  <img src="../../src/assets/examples/blink_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>blink.rosy</h3>
                <p>
                  <img src="../../src/assets/examples/blink_rosy.svg" />
                </p>
              </v-col>
            </v-row>
            <a href="#write" class="index" @click="$vuetify.goTo('#write')">
              <h1 id="write" class="pt-2">Write</h1>
            </a>
            <p>
              Este ejemplo hace que un par de <strong>motores</strong> (conectados a la placa mediante un <strong>puente H</strong>) giren hacia adelante
              a una velocidad fija.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>write.pini</h3>
                <p>
                  <img src="../../src/assets/examples/write_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>write.rosy</h3>
                <p>
                  <img src="../../src/assets/examples/write_rosy.svg" />
                </p>
              </v-col>
            </v-row>
            <a href="#read" class="index" @click="$vuetify.goTo('#read')">
              <h1 id="read" class="pt-2">Read</h1>
            </a>
            <p>
              Enciende un led si el valor obtenido por un <strong>sensor LDR</strong> es menor o igual a 300, en caso contrario, apaga el led.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>read.pini</h3>
                <p>
                  <img src="../../src/assets/examples/read_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>read.rosy</h3>
                <p>
                  <img src="../../src/assets/examples/read_rosy.svg" />
                </p>
              </v-col>
            </v-row>
            <a href="#tone-no-tone" class="index" @click="$vuetify.goTo('#tone-no-tone')">
              <h1 id="tone-no-tone" class="pt-2">Tone / No tone</h1>
            </a>
            <p>
              Emite un pitido utilizando un <strong>buzzer</strong> durante un segundo y luego se detiene por medio segundo.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>tone.pini</h3>
                <p>
                  <img src="../../src/assets/examples/tone_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>tone.rosy</h3>
                <p>
                  <img src="../../src/assets/examples/tone_rosy.svg" />
                </p>
              </v-col>
            </v-row>
            <a href="#pulse" class="index" @click="$vuetify.goTo('#pulse')">
              <h1 id="pulse" class="pt-2">Pulse</h1>
            </a>
            <p>
              Muestra el tiempo que transcurre desde que se inicia el programa hasta que se presiona un <strong>botón</strong>.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>pulse.pini</h3>
                <p>
                  <img src="../../src/assets/examples/pulse_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>pulse.rosy</h3>
                <p>
                  <img src="../../src/assets/examples/pulse_rosy.svg" />
                </p>
              </v-col>
            </v-row>
            <a href="#sensor-ultrasonico" class="index" @click="$vuetify.goTo('#sensor-ultrasonico')">
              <h1 id="sensor-ultrasonico" class="pt-2">Sensor ultrasónico</h1>
            </a>
            <p>
              Muestra la distancia entre un sensor ultrasónico y un objeto situado a la misma altura cada un segundo y medio.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>ultrasonico.pini</h3>
                <p>
                  <img src="../../src/assets/examples/ultrasonic_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>ultrasonico.rosy</h3>
                <p>
                  <img
                    :style="$vuetify.breakpoint.xs ? 'width: 330px' : ''"
                    src="../../src/assets/examples/ultrasonic_rosy.svg"
                    />
                </p>
              </v-col>
            </v-row>
            <a href="#modulo-joystick" class="index" @click="$vuetify.goTo('#modulo-joystick')">
              <h1 id="modulo-joystick" class="pt-2">Módulo joystick</h1>
            </a>
            <p>
              Muestra la posición del stick analógico en el eje X e Y, y además, indica si se está presionando el stick cada un segundo.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>joystick.pini</h3>
                <p>
                  <img src="../../src/assets/examples/joystick_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>joystick.rosy</h3>
                <p>
                  <img
                    :style="$vuetify.breakpoint.xs ? 'width: 330px' : ''"
                    src="../../src/assets/examples/joystick_rosy.svg"
                    />
                </p>
              </v-col>
            </v-row>
            <a href="#servomotor" class="index" @click="$vuetify.goTo('#servomotor')">
              <h1 id="servomotor" class="pt-2">Servomotor</h1>
            </a>
            <p>
              Muestra el ángulo inicial del eje de un servomotor, luego lo posiciona a 90 grados, espera un segundo y realiza un sondeo entre 90° y 130° a
              una velocidad de 15 y nuevamente espera otro segundo.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>servomotor.pini</h3>
                <p>
                  <img src="../../src/assets/examples/servo_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>servomotor.rosy</h3>
                <p>
                  <img src="../../src/assets/examples/servo_rosy.svg" />
                </p>
              </v-col>
            </v-row>
            <a href="#modulo-display-4-digitos" class="index" @click="$vuetify.goTo('#modulo-display-4-digitos')">
              <h1 id="modulo-display-4-digitos" class="pt-2">Módulo display 4 dígitos</h1>
            </a>
            <p>
              Muestra el número 443 en un display de 4 dígitos, luego establece un tablero con puntajes 7 y 0, espera un segundo y
              define otro tablero plus en el cúal se indican los puntajes 14 y 9 y, finalmente, limpia los valores del display.
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>display-4-digitos.pini</h3>
                <p>
                  <img src="../../src/assets/examples/d4d_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>display-4-digitos.rosy</h3>
                <p>
                  <img
                    :style="$vuetify.breakpoint.xs ? 'width: 300px' : ''"
                    src="../../src/assets/examples/d4d_rosy.svg"
                    />
                </p>
              </v-col>
            </v-row>
            <a href="#matriz-led-8x8" class="index" @click="$vuetify.goTo('#matriz-led-8x8')">
              <h1 id="matriz-led-8x8" class="pt-2">Matriz LED 8x8</h1>
            </a>
            <p>
              Enciende el led ubicado en la fila N° 1 y la columna N° 2. Luego enciende los tres primeros leds de la columna N° 4, espera un segundo y
              enciende los dos primeros leds y el último led de la fila N° 7. Por último muestra una vez el texto "hola mundo".
            </p>
            <v-row class="text-center">
              <v-col cols="12" md="6">
                <h3>matriz-led.pini</h3>
                <p>
                  <img src="../../src/assets/examples/matrix_pini.svg" />
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <h3>matriz-led.rosy</h3>
                <p>
                  <img
                    :style="$vuetify.breakpoint.xs ? 'width: 330px' : ''"
                    src="../../src/assets/examples/matrix_rosy.svg"
                    />
                </p>
              </v-col>
            </v-row>

            <div class="d-flex mt-8">
              <v-btn
                outlined
                color="info"
                class="body-1"
                style="text-transform: none"
                to="/documentacion/lenguaje"
              >
                <v-icon left>fas fa-arrow-left</v-icon>
                Anterior: Lenguaje
              </v-btn>
              <v-spacer></v-spacer>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Index from '../components/Index'

export default {
  title: 'Documentación',
  mounted() {
    if (this.$route.hash) this.$vuetify.goTo(this.$route.hash)
    else this.$vuetify.goTo(0)
  },
  components: {
    Index
  },
}
</script>